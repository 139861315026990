// @ts-strict-ignore
import _ from 'lodash';
import { DOCUMENT_LAYOUT, DOCUMENT_MARGIN_UNITS, DOCUMENT_PAPER_SIZE } from '@/reportEditor/report.constants';
import { PersistenceLevel, Store } from '@/core/flux.service';

export class PdfExportStore extends Store {
  initialize() {
    this.state = this.immutable({
      layout: DOCUMENT_LAYOUT[0], // Portrait
      paperSize: DOCUMENT_PAPER_SIZE[0], // Letter
      margin: {
        value: 0.5,
        units: DOCUMENT_MARGIN_UNITS[0].shortLabel, // in
      },
      showModal: false,
    });
  }

  /**
   * This data store is persisted at the workbench level, so it does not
   * change when an different workbook or worksheet is selected.
   */
  persistenceLevel: PersistenceLevel = 'WORKBENCH';
  static readonly storeName = 'sqPdfExportStore';

  get layout() {
    return this.state.get('layout');
  }

  get paperSize() {
    return this.state.get('paperSize');
  }

  get margin() {
    return this.state.get('margin');
  }

  get showModal() {
    return this.state.get('showModal');
  }

  dehydrate() {
    const state = _.pick(this.state.serialize(), 'margin');
    _.assign(
      state,
      { layout: _.get(this.state.get('layout'), 'value') },
      { paperSize: _.get(this.state.get('paperSize'), 'value') },
    );
    return _.omitBy(state, _.isNil);
  }

  rehydrate(dehydratedState) {
    _.assign(
      dehydratedState,
      { layout: _.find(DOCUMENT_LAYOUT, { value: dehydratedState.layout }) },
      {
        paperSize: _.find(DOCUMENT_PAPER_SIZE, {
          value: dehydratedState.paperSize,
        }),
      },
    );

    this.state.merge(_.omitBy(dehydratedState, _.isNil));
  }

  protected readonly handlers = {
    /**
     * Sets the page layout
     *
     * @param {Object} layout - orientation for the PDF, one of DOCUMENT_LAYOUTS
     */
    PDF_EXPORT_SET_LAYOUT: (layout) => {
      this.state.set('layout', layout);
    },

    /**
     * Sets the paper size
     *
     * @param {Object} paperSize - page size, one of DOCUMENT_PAPER_SIZE
     */
    PDF_EXPORT_SET_PAPER_SIZE: (paperSize) => {
      this.state.set('paperSize', paperSize);
    },

    /**
     * Sets the margin
     *
     * @param {Object} payload - Object for parameters
     * @param {Number} payload.value - Number component of margin.
     * @param {String} payload.units - Unit of measure for margin value, one of DOCUMENT_MARGIN_UNITS
     */
    PDF_EXPORT_SET_MARGIN: (payload) => {
      this.state.set('margin', { value: payload.value, units: payload.units });
    },

    PDF_EXPORT_SET_SHOW_MODAL: (payload: { showModal: boolean }) => {
      this.state.set('showModal', payload.showModal);
    },
  };
}
