import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export interface DetailsRowProps {
  label: string;
  value: string;
  valueDataTestId?: string;
  extraClassNames?: string;
}

export const DetailsRow: React.FunctionComponent<DetailsRowProps> = ({
  label,
  value,
  valueDataTestId,
  extraClassNames,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flexColumnContainer">
      <label className="min-width-80 text-right">{t(label)}</label>
      <label className={classNames('sq-fairly-dark-gray pl15', extraClassNames)} data-testid={valueDataTestId}>
        {value}
      </label>
    </div>
  );
};
