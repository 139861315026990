import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { sqFoldersApi, sqReportTemplatesApi } from '@/sdk';
import { FilterEnum } from '@/sdk/api/FoldersApi';
import { SeeqNames } from '@/main/app.constants.seeqnames';
import _ from 'lodash';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';
import { executeCommand } from '@/utilities/reportEditor.utilities';
import { useTranslation } from 'react-i18next';
import { CancelAndExecute } from '@/core/CancelAndExecute.molecule';
import { DetailsRow } from '@/reportEditor/components/DetailsRow.atom';
import { HoverTooltip } from '@/core/HoverTooltip.atom';
import { formatTime, parseISODate, TIME_FORMAT_NUMERICAL_SHORT } from '@/datetime/dateTime.utilities';
import { sqWorksheetStore } from '@/core/core.stores';
import { TEMPLATE_COMMAND } from '@/annotation/ckEditorPlugins/plugins/Template';
import { doTrack } from '@/track/track.service';
import { TableLoadingIndicator } from '@/core/TableLoadingIndicator.molecule';

export interface InsertTemplateModalProps {
  onClose: () => void;
}

export const InsertTemplateModal: React.FunctionComponent<InsertTemplateModalProps> = ({ onClose }) => {
  // Note: To create a template in the corporate drive, use "Corporate" as the folderId
  type TemplateDetails = {
    id: string;
    name: string;
    createdAt: string;
    owner?: string;
    description?: string;
  };

  const NO_TEMPLATE_SELECTED = -1;
  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState<number>(NO_TEMPLATE_SELECTED);
  const [templateHtml, setTemplateHtml] = useState<string>('');
  const [templateDetails, setTemplateDetails] = useState<TemplateDetails[]>([]);
  const [showPreview, setShowPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const getPreviewSrcUrl = (id: string) => `${APPSERVER_API_PREFIX}/report-templates/thumbnail/${id}`;
  const thumbnailOnClick = (index: number, id: string) => {
    if (selectedTemplateIndex !== index) {
      setSelectedTemplateIndex(index);
      setTemplateHtml('');
      sqReportTemplatesApi.getReportTemplate({ id }).then(({ data: { htmlTemplate } }) => {
        setTemplateHtml(htmlTemplate);
      });
      setShowPreview(true);
    } else {
      setSelectedTemplateIndex(NO_TEMPLATE_SELECTED);
      setTemplateHtml('');
      setShowPreview(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    sqFoldersApi
      .getFolders({ filter: FilterEnum.Corporate, types: [SeeqNames.Types.ReportTemplate] })
      .then(({ data }) => {
        setTemplateDetails(
          _.map(
            data.content,
            ({ id, name, createdAt, owner, description }) =>
              ({
                id,
                name,
                createdAt,
                owner: owner?.name,
                description,
              } as TemplateDetails),
          ),
        );
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Modal
      show={true}
      onHide={onClose}
      animation={false}
      data-testid="insertTemplateModal"
      size={showPreview ? 'xl' : undefined}>
      <Modal.Header closeButton={true}>
        <h4 className="mr10">{t('REPORT.CONTENT.SELECT_TEMPLATE')}</h4>
      </Modal.Header>

      <Modal.Body>
        <div className={classNames({ flexColumnContainer: !isLoading })}>
          <div
            className={classNames(
              'lightGreyBorder sq-bg-light-gray flexCenter flexWrap flexColumnContainer' +
                ' overflowYAuto min-height-390 min-width-170 btlr4 bblr4 ',
              showPreview ? 'max-width-400' : '',
            )}>
            {!_.isEmpty(templateDetails) &&
              _.map(templateDetails, ({ id }, index: number) => (
                <HoverTooltip text={templateDetails[index].name!} placement="bottom" key={id}>
                  <div
                    data-testid={`${id}-templateThumbnail`}
                    className={classNames(
                      'templateThumbnailContainer cursorPointer lightGreyBorder m10',
                      selectedTemplateIndex === index ? 'selected-template' : 'backgroundColorWhite',
                    )}
                    onClick={() => thumbnailOnClick(index, id)}>
                    <img
                      className="min-width-150 min-height-100 max-width-150 max-height-100"
                      src={`${APPSERVER_API_PREFIX}/report-templates/thumbnail/${id}`}
                    />
                  </div>
                </HoverTooltip>
              ))}

            {_.isEmpty(templateDetails) && !isLoading && (
              <div className="text-italic flexCenter">{t('REPORT.CONTENT.NO_TEMPLATES')}</div>
            )}
            {isLoading && <TableLoadingIndicator extraClassNames="sq-text-primary" testId="spinnerIcon" />}
          </div>
          {showPreview && (
            <div className="templatePreview flexRowContainer flexJustifyCenter flexSpaceBetween">
              <div
                className="min-width-220 max-height-390 overflowYAuto"
                data-testid={`${templateDetails[selectedTemplateIndex].id}-templatePreviewThumbnail`}>
                <img src={getPreviewSrcUrl(templateDetails[selectedTemplateIndex].id)} className="maxWidth100Percent" />
              </div>
              <div className="lightGreyBorderRight lightGreyBorderBottom backgroundColorOffWhite bbrr4">
                <DetailsRow label="REPORT.CONFIG.NAME" value={templateDetails[selectedTemplateIndex].name} />
                <DetailsRow
                  label="REPORT.CONFIG.OWNER"
                  value={templateDetails[selectedTemplateIndex].owner ?? t('REPORT.CONTENT.NO_TEMPLATE_OWNER')}
                />
                <DetailsRow
                  label="REPORT.CONFIG.CREATED"
                  value={formatTime(
                    parseISODate(templateDetails[selectedTemplateIndex].createdAt),
                    sqWorksheetStore.timezone,
                    TIME_FORMAT_NUMERICAL_SHORT,
                  )}
                />
                {templateDetails[selectedTemplateIndex].description && (
                  <DetailsRow
                    label="REPORT.CONFIG.DESCRIPTION"
                    value={templateDetails[selectedTemplateIndex].description!}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="flexColumnContainer mt5">
        <CancelAndExecute
          cancelAction={onClose}
          submitAction={() => {
            if (selectedTemplateIndex !== NO_TEMPLATE_SELECTED) {
              executeCommand({ command: TEMPLATE_COMMAND, templateHtml, ckEditorInstanceId: 'reportEditor' });
              doTrack('Organizer Template', templateDetails[selectedTemplateIndex].name, 'Insert');
              onClose();
            }
          }}
          submitBtnLabel={t('INSERT')}
          btnDisabled={templateHtml === ''}
          submitBtnTestId="templateInsertButton"
        />
      </Modal.Footer>
    </Modal>
  );
};
